import { createSelector } from '@reduxjs/toolkit';

import { UserRoles } from '../../model/user/types/UserRoles';
import { RootState } from '../store';

const userState = (state: RootState) => state.user;

const selectLoggedInUserId = createSelector(
  userState,
  (state) => state.loggedInUserId,
);

const selectLoggedInUser = createSelector(
  userState,
  (state) => state.loggedInUser,
);

const selectIsUserOrgMember = createSelector(
  userState,
  (state) =>
    state.loggedInUser?.organizationId?.length &&
    state.loggedInUser?.roles.includes(UserRoles.MEMBER),
);

export const userSliceSelectors = {
  selectLoggedInUserId,
  selectLoggedInUser,
  selectIsUserOrgMember,
};
