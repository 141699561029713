import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { Switch, Tooltip } from 'antd';
import omit from 'lodash/omit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { PermissionAction } from '../../../model/permissions/types/PermissionAction';
import { PermissionType } from '../../../model/permissions/types/PermissionType';
import { User } from '../../../model/user/User';
import { permissionActions } from '../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const sortPermissions = (permissions: Record<PermissionAction, boolean>) => {
  return {
    READ: permissions.READ,
    ...omit(permissions, 'READ'),
  };
};

interface MemberPermissionsProps {
  member: User;
}

export const MemberPermissions: React.FC<MemberPermissionsProps> = ({
  member,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { permissions } = member || {};

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const memberPermissions = Object.entries(permissions?.permissions || {});

  const onUpdateUserPermission = async (
    checked: boolean,
    update: { action: PermissionAction; type: PermissionType },
  ) => {
    try {
      setLoading(true);
      await dispatch(
        permissionActions.updateMemberPermissions({
          userId,
          memberId: member._id,
          permissions: {
            [update.type]: {
              [update.action]: checked,
            },
          },
        }),
      ).unwrap();
      await dispatch(permissionActions.getMembers({ userId })).unwrap();
      AtiraToast.success(t('permissions.memberPermissions.updateSuccess'));
    } catch (e: any) {
      console.error(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex justifyContent="space-around">
      {memberPermissions.map(([type, value]) => (
        <Flex flexDirection="column" gap="l">
          <Text fontSize="xm" fontWeight={'bold'}>
            {t(`common.${type.toLowerCase()}`)}
          </Text>

          <Flex flexDirection="column" gap="s">
            {Object.entries(sortPermissions(value)).map(
              ([action, value], index) => (
                <Flex
                  alignItems="center"
                  gap="s"
                  justifyContent="space-between"
                  key={`${action}-${value}-${index}`}
                >
                  {action === PermissionAction.READ && value === true ? (
                    <Tooltip
                      title={t(
                        'permissions.memberPermissions.read.notice.disabled',
                      )}
                    >
                      <Flex cursor="pointer" gap="s">
                        <Text>{t(`common.${action.toLowerCase()}`)}</Text>
                        <AtiraIcon
                          size="sm"
                          icon={faCircleExclamation}
                          color="gray"
                        />
                      </Flex>
                    </Tooltip>
                  ) : (
                    <Text>{t(`common.${action.toLowerCase()}`)}</Text>
                  )}
                  <Switch
                    disabled={loading}
                    checked={value}
                    onChange={(checked) =>
                      onUpdateUserPermission(checked, {
                        action: action as PermissionAction,
                        type: type as PermissionType,
                      })
                    }
                  />
                </Flex>
              ),
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
