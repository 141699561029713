import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const adminState = (state: RootState) => state.calculator;

const selectCalculateResponse = createSelector(
  adminState,
  (state) => state.calculateResponse,
);

const selectPrices = createSelector(adminState, (state) => state.prices || {});

export const calculateSliceSelectors = {
  selectCalculateResponse,
  selectPrices,
};
