import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { KanbanAxios } from '../../axios/kanban/kanban.axios';
import { Kanban } from '../../model/kanban/Kanban';
import { KanbanCard } from '../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../model/kanban/KanbanColumn';
import { KanbanTask } from '../../model/kanban/KanbanTask';
import { CreateKanbanColumnDto } from '../../model/kanban/dto/CreateKanabnColumnDto';
import { CreateKanbanCardDto } from '../../model/kanban/dto/CreateKanbanCardDto';
import { CreateKanbanTaskDto } from '../../model/kanban/dto/CreateKanbanTaskDto';
import { DeleteKanbanTaskDto } from '../../model/kanban/dto/DeleteKanbanTaskDto';
import { GetKanbanCardByIdDto } from '../../model/kanban/dto/GetKanbanCardByIdDto';
import { GetKanbanCardsByKanbanColumnIdDto } from '../../model/kanban/dto/GetKanbanCardsByKanbanColumnIdDto';
import { GetUserDefaultKanbanDto } from '../../model/kanban/dto/GetUserDefaultKanbanDto';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { UpdateKanbanColumnDto } from '../../model/kanban/dto/UpdateKanbanColumnDto';
import { UpdateKanbanTaskDto } from '../../model/kanban/dto/UpdateKanbanTaskDto';
import { PageResponseDto } from '../../model/meta/PageResponseDto';
import { AssignTaskDto } from '../../model/shared/dto/AssignTaskDto';
import { UnAssignTaskDto } from '../../model/shared/dto/UnAssignTaskDto';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';
import { KanbanCardReduxEntry } from './kanbanCard.reduxy-entry';

const updateCardInColumn = (column: KanbanColumn, updatedCard: KanbanCard) => {
  return {
    ...column,
    cards: column.cards?.map((card) =>
      card._id === updatedCard._id ? updatedCard : card,
    ),
  };
};

// Function to update the kanban with the new column
const updateKanban = (kanban: Kanban, updatedCard: KanbanCard) => {
  return {
    ...kanban,
    columns: kanban?.columns?.map((column) =>
      column._id === updatedCard.parentId
        ? updateCardInColumn(column, updatedCard)
        : column,
    ),
  } as Kanban;
};

interface KanbanReducer {
  kanban: Kanban | undefined;
  kanbanLoading: boolean;
}

const initialState = Object.freeze<KanbanReducer>({
  kanban: undefined,
  kanbanLoading: false,
});

/** Cards by ColumnId
 * A column and its cards
 * Used to get all columns, and if populated, each column would have its child cards
 * TODO figure out if we need this one at all or not
 */
const KanbanCardAdapter = createEntityAdapter<KanbanCardReduxEntry>({});
export const kanbanCardAdapterSelectors = KanbanCardAdapter.getSelectors();

const getKanbanCardsByKanbanColumnId = AtiraThunk<
  PageResponseDto<KanbanCard[]>,
  GetKanbanCardsByKanbanColumnIdDto
>(`/kanban/column/:id`, (dto) =>
  KanbanAxios.getKanbanCardsByKanbanColumnId(dto),
);

const createKanbanCard = AtiraThunk<void, CreateKanbanCardDto>(
  `/kanban/card/create`,
  (dto) => KanbanAxios.createKanbanCard(dto),
);

const getUserDefaultKanban = AtiraThunk<Kanban, GetUserDefaultKanbanDto>(
  '/kanban/default',
  (dto) => KanbanAxios.getUserDefaultKanban(dto),
);

const getKanbanCardById = AtiraThunk<KanbanCard, GetKanbanCardByIdDto>(
  '/kanban/card/:id',
  (dto) => KanbanAxios.getKanbanCardById(dto),
);

// TODO This call is an exception as in it returns something
// let's do it this way till we figure out a proper way to sync the kanban
// If we return the value in the same patch call, then we don't need another get call to get the updated kanban
const updateKanbanCard = AtiraThunk<Kanban, UpdateKanbanCardDto>(
  `/kanban/card/update`,
  (dto) => KanbanAxios.updateKanbanCard(dto),
);

const createKanbanTask = AtiraThunk<void, CreateKanbanTaskDto>(
  '/task/kanban',
  (dto) => KanbanAxios.createKanbanTask(dto),
);

const updateKanbanTask = AtiraThunk<KanbanTask, UpdateKanbanTaskDto>(
  '/task/kanban/:id',
  (dto) => KanbanAxios.updateKanbanTask(dto),
);

const updateKanbanColumnById = AtiraThunk<Kanban, UpdateKanbanColumnDto>(
  '/kanban/column/:id',
  (dto) => KanbanAxios.updateKanbanColumnById(dto),
);

const deleteKanbanTask = AtiraThunk<void, DeleteKanbanTaskDto>(
  '/kanban/task/:id/delete',
  (dto) => KanbanAxios.deleteKanbanTask(dto),
);

const createKanbanColumn = AtiraThunk<void, CreateKanbanColumnDto>(
  `/kanban/column/add`,
  (dto) => KanbanAxios.createKanbanColumn(dto),
);

const assignKanbanTask = AtiraThunk<void, AssignTaskDto>(
  '/assign/kanban-task',
  (dto) => KanbanAxios.assignKanbanTask(dto),
);

const unAssignKanbanTask = AtiraThunk<void, UnAssignTaskDto>(
  '/unassign/kanban-task',
  (dto) => KanbanAxios.unAssignKanbanTask(dto),
);

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getUserDefaultKanban.pending, (state, action) => {
      state.kanbanLoading = true;
    });
    addCase(getUserDefaultKanban.fulfilled, (state, action) => {
      state.kanbanLoading = false;
      state.kanban = action.payload;
    });
    addCase(getUserDefaultKanban.rejected, (state, action) => {
      state.kanbanLoading = false;
    });

    addCase(updateKanbanCard.fulfilled, (state, action) => {
      state.kanban = action.payload;
    });

    addCase(updateKanbanColumnById.fulfilled, (state, action) => {
      state.kanban = action.payload;
    });

    addCase(getKanbanCardById.fulfilled, (state, action) => {
      const updatedCard = action.payload;
      state.kanban = updateKanban(state.kanban!, updatedCard);
    });

    addCase(userActions.logout.fulfilled, (state, action) => {
      state.kanban = undefined;
    });
  },
});

export const kanbanActions = {
  getUserDefaultKanban,
  getKanbanCardsByKanbanColumnId,
  getKanbanCardById,
  createKanbanCard,
  updateKanbanCard,
  createKanbanTask,
  updateKanbanTask,
  updateKanbanColumnById,
  deleteKanbanTask,
  createKanbanColumn,
  assignKanbanTask,
  unAssignKanbanTask,
};

export default kanbanSlice.reducer;
