import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AdminAxios } from '../../axios/admin/admin.axios';
import { AdminMessage } from '../../model/admin/AdminMessage';
import { AdminStats } from '../../model/admin/AdminStats';
import { AdminGetUserMessagesDto } from '../../model/admin/dto/AdminGetUserMessagesDto';
import { AdminGetUserMessagesResponseDto } from '../../model/admin/dto/AdminGetUserMessagesResponseDto';
import { AdminMessageUserDto } from '../../model/admin/dto/AdminMessageUserDto';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { ToggleUserStatusDto } from '../../model/admin/dto/ToggleUserStatusDto';
import { UpdateUserPlanDto } from '../../model/admin/dto/UpdateUserPlanDto';
import { UpdateUserThresholdsDto } from '../../model/admin/dto/UpdateUserThresholdsDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { AtiraThunk } from '../AtiraThunk';
import { userActions } from '../user/user.slice';

interface AdminReducer {
  stats: AdminStats;
  searchUsers: SearchUsersResponseDto['data'];
  searchUsersMeta: SearchUsersDto['meta'];
  searchUsersLoading: boolean;

  searchUsersTablePageSize: number;
  searchUsersTablePage: number;

  adminMessages: AdminMessage[];
  adminMessagesLoading: boolean;
  adminMessagesPage: number;
  adminMessagesPageSize: number;
}

const initialState = Object.freeze<AdminReducer>({
  stats: {
    users: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
    entry: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
    form: {
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
    },
  },
  searchUsers: [],
  searchUsersMeta: {
    page: 0,
    count: 20,
  },
  searchUsersTablePageSize: 20,
  searchUsersTablePage: 1,
  searchUsersLoading: false,
  adminMessages: [],
  adminMessagesLoading: false,
  adminMessagesPage: 1,
  adminMessagesPageSize: 20,
});

const getAdminStats = AtiraThunk<AdminStats, GetAdminStatsDto>(
  '/admin/stats',
  (dto) => AdminAxios.getAdminStats(dto),
);

const searchUsers = AtiraThunk<SearchUsersResponseDto, SearchUsersDto>(
  '/admin/user/search',
  (dto) => AdminAxios.searchUsers(dto),
);

const updateUserPlan = AtiraThunk<void, UpdateUserPlanDto>(
  '/user/:id/plan/update',
  (dto) => AdminAxios.updateUserPlan(dto),
);

const toggleUserStatus = AtiraThunk<void, ToggleUserStatusDto>(
  '/enable/:id/user',
  (dto) => AdminAxios.toggleUserStatus(dto),
);

const updateUserThresholds = AtiraThunk<void, UpdateUserThresholdsDto>(
  '/user/:id/thersholds',
  (dto) => AdminAxios.updateUserThresholds(dto),
);

const sendMessageToUser = AtiraThunk<void, AdminMessageUserDto>(
  '/admin/user/:id/message',
  (dto) => AdminAxios.sendMessageToUser(dto),
);

const getAdminMessages = AtiraThunk<
  AdminGetUserMessagesResponseDto,
  AdminGetUserMessagesDto
>('/admin/messages', (dto) => AdminAxios.getAdminMessages(dto));

const markAdminMessagesAsRead = AtiraThunk<void, GenericWithUserId>(
  '/admin/messages/mark-as-read',
  (dto) => AdminAxios.markAdminMessagesAsRead(dto),
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCurrentSearchUsersPage: (state, action: PayloadAction<number>) => {
      state.searchUsersTablePage = action.payload;
    },

    setSearchUsersTablePageSize: (state, action) => {
      state.searchUsersTablePageSize = action.payload;
    },

    setSearchUsersTablePage: (state, action) => {
      state.searchUsersTablePage = action.payload;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getAdminStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    addCase(searchUsers.pending, (state) => {
      state.searchUsersLoading = true;
    });

    addCase(searchUsers.fulfilled, (state, action) => {
      state.searchUsers = action.payload.data;
      state.searchUsersMeta = action.payload.meta;
      state.searchUsersLoading = false;
    });

    addCase(searchUsers.rejected, (state) => {
      state.searchUsersLoading = false;
    });

    addCase(getAdminMessages.pending, (state) => {
      state.adminMessagesLoading = true;
    });
    addCase(getAdminMessages.fulfilled, (state, action) => {
      state.adminMessages = action.payload.data;
      state.adminMessagesLoading = false;
      state.adminMessagesPageSize = action.payload.meta.count;
      state.adminMessagesPage = action.payload.meta.page;
    });

    addCase(userActions.logout.fulfilled, (state) => {
      return (state = initialState);
    });
  },
});

export const adminActions = {
  ...adminSlice.actions,
  getAdminStats,
  searchUsers,
  updateUserPlan,
  updateUserThresholds,
  sendMessageToUser,
  toggleUserStatus,
  getAdminMessages,
  markAdminMessagesAsRead,
};

export default adminSlice.reducer;
