import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { DropDown } from '../components/DropDown';
import { Flex } from '../components/Flex';
import { Input } from '../components/Input';
import { Text } from '../components/Text';
import { ThresholdKinds } from '../model/threshold/types/ThresholdKinds.enum';
import { calculateSliceSelectors } from '../redux/calculator/calculator.selector';
import { calculatorActions } from '../redux/calculator/calculator.slice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { userSliceSelectors } from '../redux/user/user.selector';
import { Breakpoints } from '../theme/Breakpoints';
import { Rounded } from '../theme/Rounded';
import { Shadow } from '../theme/Shadow';
import { Spacing } from '../theme/Spacing';
import { AtiraToast } from '../utils/AtiraToast';

const Container = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  padding: ${Spacing.m};
  box-shadow: ${Shadow.MD};
  border-radius: ${Rounded.md};
  background-color: ${({ theme }) => theme.white};
  width: 90%;
  margin: auto;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 70%;
  }

  @media (min-width: ${Breakpoints.LARGE_DESKTOP}) {
    width: 45%;
  }
`;

type Threshold = Record<ThresholdKinds, number>;

enum Plans {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
}

interface Plan {
  plan: Plans;
  planPrice: number;
  planDefaultPackage: Threshold;
  addons: Threshold;
  totalPrice: number;
}

const FREE_PLAN_PACKAGE: Threshold = {
  FORMS: 3,
  ENTRIES: 500,
  KANBANS: 1,
  CUSTOM_FIELDS: 3,
  USERS: 1,
};

//Add-on entries options

const ENTRIES_OPTIONS = Array.from({ length: 50 }, (_, i) => ({
  value: (i + 1) * 1000,
  label: String((i + 1) * 1000),
}));

export const CalculatorRoute: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { control, getValues, watch } = useForm<Plan>({
    defaultValues: {
      plan: Plans.FREE,
      addons: {
        ENTRIES: 0,
        FORMS: 0,
        KANBANS: 0,
        CUSTOM_FIELDS: 0,
        USERS: 0,
      },
    },
  });

  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const prices = useAppSelector(calculateSliceSelectors.selectPrices);

  const thresholds = useAppSelector(
    calculateSliceSelectors.selectCalculateResponse,
  );

  const hasAddons = Object.values(watch('addons')).some((value) => value > 0);

  const calculatePrice = async () => {
    try {
      const dto = getValues();

      if (hasAddons) {
        setLoading(true);

        await dispatch(
          calculatorActions.calculateForThresholds({
            fields: dto.addons,
            userId,
          }),
        ).unwrap();

        AtiraToast.success(t('calculator.calculate.success'));
      }
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(calculatorActions.getPrices());

    dispatch(calculatorActions.resetState());
  }, [dispatch]);

  return (
    <Container>
      <Text fontSize="l" align="center">
        {t('calculator.title')}
      </Text>

      <Flex justifyContent="space-between">
        <Text fontSize="xm" fontWeight={'bold'}>
          {t('common.free')}
        </Text>

        <Text fontSize="xm" fontWeight={'bold'}>
          {t('calculator.add-ons')}
        </Text>
      </Flex>

      <Flex gap="m" justifyContent="space-between" alignItems="center">
        <Text>
          {t('common.entries')}:{` ${FREE_PLAN_PACKAGE.ENTRIES}`}
        </Text>

        <Flex alignItems="center" gap="s">
          {thresholds.fields.ENTRIES ? (
            <Text color="darkerSub">
              {t('calculator.addons.price_note', {
                totalPrice: thresholds.fields.ENTRIES,
                field: t('common.entry'),
                fieldPrice: prices.ENTRIES,
              })}
            </Text>
          ) : null}

          <Controller
            control={control}
            name="addons.ENTRIES"
            render={({ field: { value, onChange } }) => (
              <DropDown
                options={ENTRIES_OPTIONS}
                value={value}
                onChange={onChange}
                containerStyle={{ width: '6rem' }}
                allowClear
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex gap="m" justifyContent="space-between" alignItems="center">
        <Text>
          {t('common.forms')}: {FREE_PLAN_PACKAGE.FORMS}
        </Text>

        <Flex alignItems="center" gap="s">
          {thresholds.fields.FORMS ? (
            <Text color="darkerSub">
              {t('calculator.addons.price_note', {
                totalPrice: thresholds.fields.FORMS,
                field: t('common.forms'),
                fieldPrice: prices.FORMS,
              })}
            </Text>
          ) : null}

          <Controller
            control={control}
            name="addons.FORMS"
            render={({ field: { value, onChange } }) => (
              <Input
                value={value}
                onChange={onChange}
                type="number"
                min={0}
                width="6rem"
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex gap="m" justifyContent="space-between" alignItems="center">
        <Text>
          {t('common.kanbans')}: {FREE_PLAN_PACKAGE.KANBANS}
        </Text>

        <Flex alignItems="center" gap="s">
          {thresholds.fields.KANBANS ? (
            <Text color="darkerSub">
              {t('calculator.addons.price_note', {
                totalPrice: thresholds.fields.KANBANS,
                field: t('common.kanban'),
                fieldPrice: prices.KANBANS,
              })}
            </Text>
          ) : null}

          <Controller
            control={control}
            name="addons.KANBANS"
            render={({ field: { value, onChange } }) => (
              <Input
                value={value}
                onChange={onChange}
                type="number"
                min={0}
                width="6rem"
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex gap="m" justifyContent="space-between" alignItems="center">
        <Text>
          {t('common.custom_fields')}: {FREE_PLAN_PACKAGE.CUSTOM_FIELDS}
        </Text>

        <Flex alignItems="center" gap="s">
          {thresholds.fields.CUSTOM_FIELDS ? (
            <Text color="darkerSub">
              {t('calculator.addons.price_note', {
                totalPrice: thresholds.fields.CUSTOM_FIELDS,
                field: t('common.custom_field'),
                fieldPrice: prices.CUSTOM_FIELDS,
              })}
            </Text>
          ) : null}

          <Controller
            control={control}
            name="addons.CUSTOM_FIELDS"
            render={({ field: { value, onChange } }) => (
              <Input
                value={value}
                onChange={onChange}
                type="number"
                min={0}
                width="6rem"
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex gap="m" justifyContent="space-between" alignItems="center">
        <Text>
          {t('common.users')}: {FREE_PLAN_PACKAGE.USERS}
        </Text>

        <Flex alignItems="center" gap="s">
          {thresholds.fields.USERS ? (
            <Text color="darkerSub">
              {t('calculator.addons.price_note', {
                totalPrice: thresholds.fields.USERS,
                field: t('common.user'),
                fieldPrice: prices.USERS,
              })}
            </Text>
          ) : null}

          <Controller
            control={control}
            name="addons.USERS"
            render={({ field: { value, onChange } }) => (
              <Input
                value={value}
                onChange={onChange}
                type="number"
                min={0}
                width="6rem"
              />
            )}
          />
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="l" fontWeight={'bold'}>
          {t('calculator.total_price')}
          {thresholds.total ? `${thresholds.total}$` : null}
        </Text>

        <Button
          onClick={calculatePrice}
          width="7rem"
          height="2.5rem"
          loading={loading}
          disabled={!hasAddons}
        >
          {t('calculator.calculate')}
        </Button>
      </Flex>
    </Container>
  );
};
