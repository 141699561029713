import { faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons/faCircleUser';
import { Badge, Tooltip } from 'antd';
import { Dropdown, MenuProps, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Images } from '../../assets';
import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { Text } from '../../components/Text';
import { TextWithTruncated } from '../../components/TextWithTruncated';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { UserRoles } from '../../model/user/types/UserRoles';
import { adminSliceSelectors } from '../../redux/admin/admin.selector';
import { adminActions } from '../../redux/admin/admin.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { userActions } from '../../redux/user/user.slice';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { Theme } from '../../theme/Theme';
import { truncateString } from '../../utils/String';
import { NotificationsDrawer } from './components/NotificationsDrawer';

const SettingsWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-inline-end: ${Spacing.m};
  width: 30rem;
  gap: ${Spacing.l};
`;

const StyleLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
`;

const UserProfileWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${Rounded.circle};
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  white-space: wrap;
  text-overflow: wrap;
  word-break: break-all;
  font-size: 1rem;
  color: ${({ theme }) => theme.black};
`;

const StyledHr = styled.hr`
  height: 1px;
  border: 0;
  background-color: ${({ theme }) => theme.darkerSub};
`;

const LogoutButton = styled(Button)`
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.main};
  border: ${({ theme }) => `0.1rem solid ${theme.main}`};
  width: 100%;
  font-size: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.sub};

  &:hover {
    color: ${({ theme }) => theme.sub};
  }
`;

const StyledCopyButton = styled(CopyButton)`
  padding: 0;
`;

export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const unreadMessages = useAppSelector(
    adminSliceSelectors.selectAdminMessages,
  )?.filter(({ read }) => read === false);

  const { t } = useTranslation();
  const { theme } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const adminMessagesPage = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPage,
  );
  const adminMessagesPageSize = useAppSelector(
    adminSliceSelectors.selectAdminMessagesPageSize,
  );

  const isStaging = process.env.REACT_APP_ENV === 'staging';

  const onLogout = () => {
    dispatch(userActions.logout());
  };

  const items: MenuProps['items'] = [
    {
      key: 'g1',
      type: 'group',
      children: [
        {
          key: '1',
          label: (
            <Link to={'/profile'} style={{ cursor: 'pointer' }}>
              <StyledText>
                {loggedInUser?.name ? (
                  <Tooltip title={loggedInUser.name}>
                    {truncateString(loggedInUser.name)}
                  </Tooltip>
                ) : (
                  t('common.user_name')
                )}
              </StyledText>
            </Link>
          ),
        },
        {
          key: '2',
          label: loggedInUser?.email ? (
            <Flex justifyContent="space-between" alignItems="center" gap="s">
              <StyledText>
                <Tooltip title={loggedInUser.email}>
                  {truncateString(loggedInUser.email, 18)}
                </Tooltip>
              </StyledText>

              <StyledCopyButton copy={loggedInUser.email} />
            </Flex>
          ) : (
            <StyledText>{t('common.user_email')}</StyledText>
          ),
          className: 'user-email',
        },
        {
          key: '3',
          label: (
            <Link to={'/permissions'}>
              <StyledText>{t('header.dropdown.permissions')}</StyledText>
            </Link>
          ),
        },
      ],
    },

    {
      key: '4',
      label: <StyledHr />,
      className: 'hr',
    },
    {
      key: 'g2',
      style: { height: '5rem' },
      type: 'group',
      children: [
        {
          key: '5',
          label: (
            <Link to={'/help/help'}>
              <Text fontSize="m">{t('common.help_support')}</Text>
            </Link>
          ),
        },
      ],
    },
    {
      key: '6',
      label: <StyledHr />,
      className: 'hr',
    },
    {
      key: '7',
      label: (
        <LogoutButton onClick={onLogout}>{t('header.logout')}</LogoutButton>
      ),
      className: 'logout-button',
    },
  ];

  useEffect(() => {
    if (isEmpty(loggedInUser)) {
      navigate('/', { replace: true });
    }
  }, [loggedInUser, navigate]);

  useEffect(() => {
    dispatch(
      adminActions.getAdminMessages({
        userId: loggedInUser?._id!,
        meta: { page: adminMessagesPage - 1, count: adminMessagesPageSize },
      }),
    );
  }, [dispatch, loggedInUser?._id]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        width: '100%',
        zIndex: '100',
        height: '3.5rem',
      }}
    >
      <Flex
        background={theme.main}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height={'100%'}
        gap="xl"
        padding="s"
      >
        <Flex alignItems="center" width={'17rem'} paddingLeft="s">
          <StyleLink to={'/'}>
            <AtiraImage src={Images.AtiraSpaceLogoBetaSub} width="100%" />
          </StyleLink>
        </Flex>

        {isStaging ? (
          <Text color="red" fontSize="l">
            /\ STAGING ENV /\
          </Text>
        ) : null}

        <SettingsWrapper>
          <Space direction="vertical">
            <Flex alignItems="center" gap="s">
              <TextWithTruncated
                length={Lengths.NAME}
                color="white"
                style={{ flex: 1 }}
              >
                {loggedInUser?.name as string}
              </TextWithTruncated>
              <Dropdown
                overlayStyle={{ width: '15rem', borderRadius: '0.5rem' }}
                menu={{ items }}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
              >
                <UserProfileWrapper>
                  {loggedInUser?.picture ? (
                    <AtiraImage src={loggedInUser.picture} />
                  ) : (
                    <AtiraIcon icon={faCircleUser} size="3x" color="sub" />
                  )}
                </UserProfileWrapper>
              </Dropdown>
            </Flex>
          </Space>

          {loggedInUser?.roles?.includes(UserRoles.ADMIN) ? (
            <Tooltip title={t('admin.header.title')}>
              <StyledLink to={'/admin'}>
                <AtiraIcon icon={faUsersGear} size="2x" />
              </StyledLink>
            </Tooltip>
          ) : null}

          <Tooltip title={t('common.notifications')}>
            <Badge
              count={unreadMessages?.length}
              color={unreadMessages?.length ? Theme.main : Theme.transparent}
            >
              <Button
                onClick={() => setOpen(true)}
                backgroundColor="transparent"
                margin="0"
                padding="0"
                icon={faBell}
                color="sub"
                iconWidth="2x"
              />
            </Badge>
          </Tooltip>

          <NotificationsDrawer open={open} onClose={() => setOpen(false)} />

          <LanguageSwitcher />

          <StyleLink to={'/calculator'}>{t('header.calculator')}</StyleLink>

          {loggedInUser?.kind !== UserKind.PAID && (
            <StyleLink to={'/subscription'}>
              {t('header.subscription')}
            </StyleLink>
          )}
        </SettingsWrapper>
      </Flex>
    </div>
  );
};
