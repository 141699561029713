/* eslint-disable no-nested-ternary */
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraEmpty } from '../../../components/AtiraEmpty';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { SpinnerFullScreen } from '../../../components/SpinnerFullScreen';
import { Text } from '../../../components/Text';
import { WarningModal } from '../../../components/WarningModal';
import { PermissionsInviteMembersDto } from '../../../model/permissions/dto/PermissionsInviteMembersDto';
import { permissionSliceSelectors } from '../../../redux/permission/permission.selector';
import { permissionActions } from '../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { PendingMember } from '../components/invited-members/PendingMember';
import { MemberInviteModal } from '../components/modals/MemberInviteModal';
import { usePermissionsContext } from '../permissions-context';

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${Shadow.SM};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  flex-direction: column;
`;

const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.s};
  margin: 0;
  padding: ${Spacing.s};
  font-size: 1rem;
`;

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `2px solid ${theme.main}`};
  margin-bottom: ${Spacing.xl};
  padding-bottom: ${Spacing.s};
`;

export const PermissionsInvitesTab: React.FC = () => {
  const [cancelInviteLoading, setCancelInviteLoading] = useState(false);

  const { t } = useTranslation();
  const formMethods = useForm<PermissionsInviteMembersDto>();
  const {
    inviteMemberModalVisible,
    setInviteMemberModalVisible,
    currentInvite,
    cancelInviteModalVisible,
    setCancelInviteModalVisible,
    setCurrentInvite,
  } = usePermissionsContext();

  const invites = useAppSelector(permissionSliceSelectors.selectInvitedMembers);
  const loading = useAppSelector(
    permissionSliceSelectors.selectedInvitedMembersLoading,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const members = useAppSelector(permissionSliceSelectors.selectMembers);
  const user = useAppSelector(userSliceSelectors.selectLoggedInUser)!;
  const canInvite = (user.threshold?.thresholds?.USERS || 0) > members?.length;

  const dispatch = useAppDispatch();

  const onCancelInvite = async () => {
    try {
      setCancelInviteLoading(true);
      await dispatch(
        permissionActions.cancelPermissionInvite({
          userId,
          inviteId: currentInvite!._id,
        }),
      ).unwrap();
      await dispatch(permissionActions.getInvitedMembers({ userId })).unwrap();
      setCurrentInvite(undefined);
      setCancelInviteModalVisible(false);
      AtiraToast.success('Invite canceled');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setCancelInviteLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Container>
        <Header>
          <Text fontWeight={'bold'} fontSize="xm">
            {t('permissions.total.members', { count: members?.length })}
          </Text>

          <HeaderButton
            disabled={!canInvite}
            onClick={() => setInviteMemberModalVisible(true)}
          >
            <AtiraIcon icon={faPlus} color="white" />

            {t('permission.add_member')}
          </HeaderButton>
        </Header>

        <Flex flexDirection="column" gap="m">
          {loading && !invites?.length ? (
            <SpinnerFullScreen />
          ) : !invites.length ? (
            <AtiraEmpty description={t('permissions.invites.empty')} />
          ) : (
            invites.map((invite) => (
              <PendingMember invite={invite} key={invite._id} />
            ))
          )}
        </Flex>

        <MemberInviteModal
          isOpen={inviteMemberModalVisible}
          onClose={() => setInviteMemberModalVisible(false)}
        />

        <WarningModal
          isOpen={cancelInviteModalVisible}
          onClose={() => setCancelInviteModalVisible(false)}
          title={t('permissions.invite.cancel.modal.title')}
          description={t('permissions.invite.cancel.modal.description', {
            email: currentInvite?.inviteeEmail,
          })}
          onConfirm={onCancelInvite}
          loading={cancelInviteLoading}
        />
      </Container>
    </FormProvider>
  );
};
