import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { truncateString } from '../utils/String';
import { ParagraphProps, Text } from './Text';

const StyledText = styled(Text)`
  color: ${(props) =>
    props.color ? props.theme[props.color] : props.theme['textColor']};
  white-space: nowrap;
`;

export type TextWithTruncatedProps = {
  children: string;
  length?: number;
} & ParagraphProps;

export const TextWithTruncated: React.FC<TextWithTruncatedProps> = ({
  children,
  length = 40,
  ...props
}) => {
  if (children && children.length > length) {
    return (
      <Tooltip title={children}>
        <StyledText {...props}>{truncateString(children, length)}</StyledText>
      </Tooltip>
    );
  }

  return <StyledText {...props}>{children}</StyledText>;
};
