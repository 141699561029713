import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { Collapse, CollapseProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { AtiraIcon } from './AtiraIcon';

const StyledCollapse = styled(Collapse)`
  overflow: hidden;

  .ant-collapse-header {
    align-items: center !important;
  }
`;

enum CollapsibleType {
  header = 'header',
  disabled = 'disabled',
  icon = 'icon',
}

enum ExpandIconPosition {
  start = 'start',
  end = 'end',
  right = 'right',
  left = 'left',
}

interface AtiraCollapseProps extends CollapseProps {
  defaultActiveKey?: string[];
  bordered?: boolean;
  accordion?: boolean;
  expandIconPosition?: keyof typeof ExpandIconPosition;
  collapsible?: keyof typeof CollapsibleType;
}

export const AtiraCollapse: React.FC<AtiraCollapseProps> = ({
  items = [],
  defaultActiveKey = ['1'],
  bordered,
  accordion,
  expandIconPosition = ExpandIconPosition.start,
  collapsible = CollapsibleType.header,
  ...props
}) => {
  return (
    <StyledCollapse
      items={items}
      defaultActiveKey={defaultActiveKey}
      bordered={bordered}
      accordion={accordion}
      expandIconPosition={ExpandIconPosition[expandIconPosition]}
      collapsible={CollapsibleType[collapsible]}
      expandIcon={({ isActive }) =>
        isActive ? (
          <AtiraIcon cursor="pointer" icon={faArrowUp} color="main" size="xl" />
        ) : (
          <AtiraIcon
            cursor="pointer"
            icon={faArrowDown}
            color="main"
            size="xl"
          />
        )
      }
      {...props}
    />
  );
};
