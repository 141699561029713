import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const adminState = (state: RootState) => state.admin;

const selectAdminStats = createSelector(adminState, (state) => state.stats);

const selectUsers = createSelector(
  adminState,
  (state) => state.searchUsers || [],
);

const selectSearchUsersTablePageSize = createSelector(
  adminState,
  (state) => state.searchUsersTablePageSize || 20,
);

const selectSearchUsersTablePage = createSelector(
  adminState,
  (state) => state.searchUsersTablePage || 1,
);

const selectUsersMeta = createSelector(
  adminState,
  (state) => state.searchUsersMeta,
);

const searchUsersLoading = createSelector(
  adminState,
  (state) => state.searchUsersLoading,
);

const selectAdminMessages = createSelector(
  adminState,
  (state) => state.adminMessages || [],
);

const selectAdminMessagesLoading = createSelector(
  adminState,
  (state) => state.adminMessagesLoading,
);

const selectAdminMessagesPage = createSelector(
  adminState,
  (state) => state.adminMessagesPage || 1,
);

const selectAdminMessagesPageSize = createSelector(
  adminState,
  (state) => state.adminMessagesPageSize || 20,
);

export const adminSliceSelectors = {
  selectAdminStats,
  selectUsers,
  searchUsersLoading,
  selectUsersMeta,
  selectSearchUsersTablePageSize,
  selectSearchUsersTablePage,
  selectAdminMessages,
  selectAdminMessagesLoading,
  selectAdminMessagesPage,
  selectAdminMessagesPageSize,
};
