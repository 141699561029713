import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { Spacing } from '../../theme/Spacing';
import { Button } from '../Button';
import { Flex } from '../Flex';
import {
  TextWithTruncated,
  TextWithTruncatedProps,
} from '../TextWithTruncated';
import { WarningModal } from '../WarningModal';

const StyledAntdModal = styled(AntdModal)`
  .ant-modal-close {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.transparent};
  color: ${({ theme }) => theme.gray};
`;

const StyledTitle = styled(TextWithTruncated)`
  font-size: 1.5rem !important;
  font-weight: bold;
  text-align: start !important;
  color: ${({ theme }) => theme.gray};
`;

const StyledHr = styled.hr`
  height: 1.5px;
  opacity: 1;
  background-color: ${({ theme }) => theme.lightgray};
  width: 100%;
  border: 0;
  margin-bottom: ${Spacing.m};
`;

interface ModalProps extends AntdModalProps {
  children: React.ReactNode;
  title?: string;
  titleProps?: TextWithTruncatedProps;
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
  open: boolean;
  onClose?: VoidFunction;
  className?: string;
  showCloseWarning?: boolean;
  closeWarningDescription?: string;
  onConfirmClose?: VoidFunction;
}

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  content,
  overlay,
  title,
  titleProps = {},
  open,
  className,
  closeWarningDescription,
  showCloseWarning = false,
  onConfirmClose,
  ...props
}) => {
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const { theme } = useTheme();

  const onLocalClose = showCloseWarning
    ? () => setWarningModalVisible(true)
    : onClose;

  const onLocalConfirmClose = () => {
    setWarningModalVisible(false);
    onConfirmClose?.();
    onClose?.();
  };

  return (
    <Fragment>
      <StyledAntdModal
        className={className}
        styles={{ content: { backgroundColor: theme.white } }}
        open={open}
        onClose={onLocalClose}
        onCancel={onLocalClose}
        footer={null}
        title={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            backgroundColor={theme.white}
          >
            {title ? <StyledTitle {...titleProps}>{title}</StyledTitle> : null}
            {props.maskClosable !== false ? (
              <CloseButton icon={faX} iconWidth="lg" onClick={onLocalClose} />
            ) : null}
          </Flex>
        }
        {...props}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledHr />

          {children}
        </Flex>
      </StyledAntdModal>

      {showCloseWarning ? (
        <WarningModal
          description={closeWarningDescription || t('warning.sure_to_leave')}
          isOpen={warningModalVisible}
          onClose={() => setWarningModalVisible(false)}
          onConfirm={onLocalConfirmClose}
        />
      ) : null}
    </Fragment>
  );
};
