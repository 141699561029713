import pick from 'lodash/pick';
import styled, { CSSProperties } from 'styled-components';

import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

type FlexProps = Pick<
  CSSProperties,
  | 'flex'
  | 'flexDirection'
  | 'flexBasis'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexWrap'
  | 'alignItems'
  | 'alignSelf'
  | 'justifyContent'
  | 'alignContent'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'height'
  | 'maxHeight'
  | 'minHeight'
  | 'position'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'transform'
  | 'cursor'
  | 'border'
  | 'borderTop'
  | 'borderLeft'
  | 'borderBottom'
  | 'borderRight'
  | 'overflow'
  | 'overflowY'
  | 'overflowX'
  | 'opacity'
  | 'background'
  | 'boxSizing'
> & {
  className?: string;
  gap?: keyof typeof Spacing;
  margin?: keyof typeof Spacing;
  marginTop?: keyof typeof Spacing;
  marginBottom?: keyof typeof Spacing;
  marginLeft?: keyof typeof Spacing;
  marginRight?: keyof typeof Spacing;
  cursor?: CSSProperties['cursor'];
  padding?: keyof typeof Spacing;
  paddingTop?: keyof typeof Spacing;
  paddingBottom?: keyof typeof Spacing;
  paddingLeft?: keyof typeof Spacing;
  paddingRight?: keyof typeof Spacing;
  backgroundColor?: CSSProperties['backgroundColor'];
  borderRadius?: keyof typeof Rounded;
  borderTopLeftRadius?: keyof typeof Rounded;
  borderTopRightRadius?: keyof typeof Rounded;
  borderBottomLeftRadius?: keyof typeof Rounded;
  borderBottomRightRadius?: keyof typeof Rounded;
  debug?: boolean;
};

const spacingKeys: (keyof FlexProps)[] = [
  'top',
  'bottom',
  'left',
  'right',
  'gap',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
];

const borderRadiusKeys: (keyof FlexProps)[] = [
  'borderRadius',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
];

export const Flex = styled.div.attrs(
  ({ debug, backgroundColor, ...props }: FlexProps) => {
    const themable = Object.entries(
      pick(props, [...spacingKeys, ...borderRadiusKeys]),
    ).reduce((style, [key, value]) => {
      if (spacingKeys.includes(key as keyof FlexProps)) {
        return {
          ...style,
          [key]: Spacing[value as keyof typeof Spacing],
        };
      }
      if (borderRadiusKeys.includes(key as keyof FlexProps)) {
        return {
          ...style,
          [key]: Rounded[value as keyof typeof Rounded],
        };
      }
      return style;
    }, {});

    return {
      style: {
        backgroundColor,
        ...(props as FlexProps),
        ...themable,
      },
    };
  },
)<FlexProps>`
  display: flex;
  ${({ debug }) => debug && 'border: 1px solid red;'}
`;
