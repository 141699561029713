import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../components/Flex';
import { Text } from '../components/Text';
import { Modal } from '../components/modal/Modal';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const StyledModal = styled(Modal)`
  .ant-modal-header button {
    display: none;
  }

  .ant-modal-title div {
    justify-content: center !important;
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.sub};
  text-decoration: none;
  background-color: ${({ theme }) => theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  border-radius: ${Rounded.md};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
    color: ${({ theme }) => theme.sub};
  }
`;

export const UserDisabledNotificationModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledModal open title={t('user_disabled.modal.title')}>
      <Flex flex={1} flexDirection="column" alignItems="center" gap="m">
        <Text align="center">{t('user_disabled.modal.description')}</Text>
        <StyledLink href="mailto:support@atiragrup.com">
          {t('common.contact_support')}
        </StyledLink>
      </Flex>
    </StyledModal>
  );
};
