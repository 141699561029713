import { createSlice } from '@reduxjs/toolkit';

import { CalculatorAxios } from '../../axios/calculator/calculator.axios';
import { CalculateForThresholdsDto } from '../../model/calculator/dto/CalculateForThresholdDto';
import { CalculateForThresholdsResponseDto } from '../../model/calculator/dto/CalculateForThresholdresponseDto';
import { GetPricesResponseDto } from '../../model/calculator/dto/GetPricesResponseDto';
import { AtiraThunk } from '../AtiraThunk';

interface CalculatorReducer {
  calculateResponse: CalculateForThresholdsResponseDto;
  prices: GetPricesResponseDto;
}

const initialState = Object.freeze<CalculatorReducer>({
  calculateResponse: {
    fields: {
      CUSTOM_FIELDS: 0,
      ENTRIES: 0,
      FORMS: 0,
      KANBANS: 0,
      USERS: 0,
    },
    total: 0,
  },
  prices: {
    CUSTOM_FIELDS: 0,
    ENTRIES: 0,
    FORMS: 0,
    KANBANS: 0,
    USERS: 0,
  },
});

const calculateForThresholds = AtiraThunk<
  CalculateForThresholdsResponseDto,
  CalculateForThresholdsDto
>('/calculate', (dto) => CalculatorAxios.calculateForThresholds(dto));

const getPrices = AtiraThunk<GetPricesResponseDto, void>(
  '/calculate/prices',
  () => CalculatorAxios.getPrices(),
);

const calculatorSlice = createSlice({
  name: 'claculator',
  initialState,
  reducers: {
    resetState: (state) => {
      state.calculateResponse = initialState.calculateResponse;
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(calculateForThresholds.fulfilled, (state, action) => {
      state.calculateResponse = action.payload;
    });

    addCase(getPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
    });
  },
});

export const calculatorActions = {
  ...calculatorSlice.actions,
  calculateForThresholds,
  getPrices,
};

export default calculatorSlice.reducer;
