import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { Select, SelectProps } from 'antd';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Spacing } from '../theme/Spacing';
import { AtiraIcon } from './AtiraIcon';
import { Flex } from './Flex';
import { Text } from './Text';

const StyledSelectContainer = styled(Flex)<{ height?: string }>`
  flex-direction: column;
  gap: ${Spacing.s};

  .ant-select-selector:hover {
    border: 1px solid ${({ theme }) => theme.main} !important;
  }

  .ant-select-selector {
    height: ${({ height }) => height || '2.3rem'} !important;
  }

  .ant-select-dropdown {
    z-index: 10000 !important;
  }

  input,
  .ant-select-single {
    height: 100% !important;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.red};
`;

interface DropDownProps extends SelectProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  title?: string;
  containerStyle?: CSSProperties;
  onChange: (v: string) => void;
  required?: boolean;
  valid?: boolean;
  errorMessage?: string;
}

export const DropDown: React.FC<DropDownProps> = ({
  onChange,
  options,
  value,
  width,
  height,
  title,
  borderRadius,
  containerStyle,
  required = false,
  valid,
  errorMessage,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledSelectContainer style={containerStyle} height={height}>
      {title ? (
        <label>
          {title}
          {required ? <Required> *</Required> : null}
        </label>
      ) : null}
      <Select
        onChange={onChange}
        value={value}
        options={options}
        suffixIcon={<AtiraIcon icon={faAngleDown} color="darkSub" />}
        placeholder={props.placeholder || t('common.select-placeholder')}
        {...props}
      />
      {valid === false && errorMessage ? (
        <Text color="red" fontSize="s">
          {errorMessage}
        </Text>
      ) : null}
    </StyledSelectContainer>
  );
};
