import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import App from './App';
import './GlobalStyle';
import { initSentry } from './Sentry';
import { setLangugeAndFont } from './SetLanguageAndFont';
import './i18n';

initSentry();
setLangugeAndFont();
ReactGA.initialize('G-KFNB919X4D', {
  testMode: process.env.NODE_ENV !== 'production',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
