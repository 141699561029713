import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { add } from 'date-fns';
import isDate from 'lodash/isDate';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { permissionSliceSelectors } from '../../../redux/permission/permission.selector';
import { useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 7rem;
`;

type EntryDetailsDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
};

export const EntryDetailsDrawer: React.FC<EntryDetailsDrawerProps> = ({
  isOpen,
  onClose,
  entry,
}) => {
  const { t } = useTranslation();

  const entryDate = standardDate(entry?.createdAt || '');
  const members = useAppSelector(permissionSliceSelectors.selectMembers);
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const canShowAssigneeInfo =
    entry?.assigneeId && entry.assigneeId !== loggedInUserId;

  return (
    <Drawer title={t('common.details')} onClose={onClose} open={isOpen}>
      <Flex flexDirection="column" gap="m">
        {entry?.markedForDeletion ? (
          <Text color="red" align="center">
            {t('entries.delete.modal.notice.title', {
              date: standardDate(
                add(new Date(entry?.markedForDeletionAt || ''), {
                  days: 30,
                }),
              ),
            })}
          </Text>
        ) : null}

        <StyledFlex>
          <BoldText>{t('common.kind')}:</BoldText>

          <ValueText>{t(`entries.table.entry.${entry?.kind}`)}</ValueText>
        </StyledFlex>

        {entry?.parentForm?.title ? (
          <StyledFlex>
            <BoldText>{t('entries.table.header.form_title')}:</BoldText>

            <ValueText>{entry?.parentForm.title}</ValueText>
          </StyledFlex>
        ) : null}

        {canShowAssigneeInfo ? (
          <StyledFlex flexDirection="column">
            <StyledFlex>
              <BoldText color="red">{t('common.assignee')}:</BoldText>

              <ValueText>
                {members.find(({ _id }) => _id === entry.assigneeId)?.name}
              </ValueText>
            </StyledFlex>
          </StyledFlex>
        ) : null}

        <hr />

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.details')}</BoldText>

          {Object.entries(entry?.fields || {}).map(([key, value]) =>
            value ? (
              <StyledFlex key={key}>
                <Flex alignItems="center" gap="s">
                  <AtiraIcon icon={faCircle} color="main" size="xs" />
                  <BoldText>{t(`common.${key}`)}:</BoldText>
                </Flex>

                <ValueText>
                  {isDate(value) ? standardDate(value, true) : value}
                </ValueText>
              </StyledFlex>
            ) : null,
          )}
        </Flex>

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.info')}</BoldText>

          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('entries.table.header.submit_time')}:</BoldText>
            </Flex>

            <ValueText>{entryDate}</ValueText>
          </StyledFlex>

          <StyledFlex>
            <Flex alignItems="center" gap="s">
              <AtiraIcon icon={faCircle} size="xs" color="main" />

              <BoldText>{t('common.id')}:</BoldText>
            </Flex>

            <ValueText>{entry?._id}</ValueText>
          </StyledFlex>
        </Flex>

        <Flex flexDirection="column" gap="s">
          <BoldText fontSize="xm">{t('common.description')}</BoldText>

          <StyledTextarea
            value={entry?.description}
            placeholder={t('entries.table.detailsModal.textarea.placeholder')}
            disabled
            style={{ resize: 'none' }}
            rows={3}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
