import { AdminStats } from '../../model/admin/AdminStats';
import { AdminGetUserMessagesDto } from '../../model/admin/dto/AdminGetUserMessagesDto';
import { AdminGetUserMessagesResponseDto } from '../../model/admin/dto/AdminGetUserMessagesResponseDto';
import { AdminMessageUserDto } from '../../model/admin/dto/AdminMessageUserDto';
import { GetAdminStatsDto } from '../../model/admin/dto/GetAdminStatsDto';
import { SearchUsersDto } from '../../model/admin/dto/SearchUsersDto';
import { SearchUsersResponseDto } from '../../model/admin/dto/SearchUsersResponseDto';
import { ToggleUserStatusDto } from '../../model/admin/dto/ToggleUserStatusDto';
import { UpdateUserPlanDto } from '../../model/admin/dto/UpdateUserPlanDto';
import { UpdateUserThresholdsDto } from '../../model/admin/dto/UpdateUserThresholdsDto';
import { GenericWithUserId } from '../../model/shared/dto/GenericWithUserId';
import { AtiraApi } from '../AxiosClient';

export class AdminAxios {
  static getAdminStats(dto: GetAdminStatsDto) {
    return AtiraApi.post<AdminStats>('/admin/stats', dto).then(
      ({ data }) => data,
    );
  }

  static searchUsers(dto: SearchUsersDto) {
    return AtiraApi.post<SearchUsersResponseDto>(
      `/admin/user/search`,
      dto,
    ).then(({ data }) => data);
  }

  static updateUserPlan(dto: UpdateUserPlanDto) {
    return AtiraApi.post<void>(`/user/${dto.userId}/plan/update`, dto).then(
      ({ data }) => data,
    );
  }

  static toggleUserStatus(dto: ToggleUserStatusDto) {
    return AtiraApi.post<void>(`/admin/user/${dto.userId}/toggle-status`).then(
      ({ data }) => data,
    );
  }

  static updateUserThresholds(dto: UpdateUserThresholdsDto) {
    return AtiraApi.post<void>(
      `/admin/user/${dto.userId}/manage-thresholds`,
      dto,
    ).then(({ data }) => data);
  }

  static sendMessageToUser(dto: AdminMessageUserDto) {
    return AtiraApi.post<void>(`/admin/user/${dto.userId}/message`, {
      message: dto.message,
    }).then(({ data }) => data);
  }

  static getAdminMessages(dto: AdminGetUserMessagesDto) {
    return AtiraApi.post<AdminGetUserMessagesResponseDto>(
      `/admin/user/${dto.userId}/get-messages`,
      dto,
    ).then(({ data }) => data);
  }

  static markAdminMessagesAsRead(dto: GenericWithUserId) {
    return AtiraApi.post<void>(
      `/admin/user/${dto.userId}/mark-messages-as-read`,
    ).then(({ data }) => data);
  }
}
