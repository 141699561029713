import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';
import { Tooltip } from 'antd';
import React from 'react';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { Flex } from '../../../../components/Flex';
import { Input } from '../../../../components/Input';
import { Text } from '../../../../components/Text';
import { TextWithTruncated } from '../../../../components/TextWithTruncated';
import { Modal } from '../../../../components/modal/Modal';
import { PermissionsInviteMembersDto } from '../../../../model/permissions/dto/PermissionsInviteMembersDto';
import { permissionSliceSelectors } from '../../../../redux/permission/permission.selector';
import { permissionActions } from '../../../../redux/permission/permission.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { Rounded } from '../../../../theme/Rounded';
import { Spacing } from '../../../../theme/Spacing';
import { AtiraToast } from '../../../../utils/AtiraToast';
import { Regexes } from '../../../../utils/String';

const AddButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
  height: 2.3rem;
  margin-top: auto;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const DeleteButton = styled(Button)`
  padding: ${Spacing.s};
  margin: 0;
  background-color: ${({ theme }) => theme.darkSub};
  border: ${({ theme }) => `1px solid ${theme.darkerSub}`};
  color: ${({ theme }) => theme.darkerSub};

  &:hover {
    background-color: ${({ theme }) => theme.darkerSub};
    color: ${({ theme }) => theme.white};
  }
`;

const SubmitButton = styled(Button)`
  width: 7rem;
  height: 2.3rem;
  font-size: 1rem;
  margin: auto;

  &:hover {
    background-color: ${({ theme }) => theme.lightMain};
  }
`;

const EmailWrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.sub};
  border-radius: ${Rounded.md};
  justify-content: space-between;
  padding: 0.3rem;
`;

interface MemberInviteModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const MemberInviteModal: React.FC<MemberInviteModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    getValues: getValues2,
    reset: reset2,
  } = useForm<{
    email: string;
  }>({
    defaultValues: { email: '' },
  });

  const { control, getValues, handleSubmit, reset } =
    useFormContext<PermissionsInviteMembersDto>();
  const { append, fields, remove, replace } = useFieldArray({
    control,
    name: 'emails',
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const loading = useAppSelector(permissionSliceSelectors.selectInviteLoading);

  const onAddEmail = () => {
    append({ content: getValues2('email') });
    reset2();
  };

  const onInvite = async () => {
    try {
      const values = getValues();
      const emails = values.emails.map((email) => email.content) as any;
      await dispatch(
        permissionActions.inviteMembers({ userId, emails }),
      ).unwrap();
      await dispatch(permissionActions.getInvitedMembers({ userId })).unwrap();
      AtiraToast.success(t('permissions.invites.invite_modal.success'));
      onClose();
      reset();
      reset2();
      replace([]);
    } catch (e: any) {
      console.error(e);
      AtiraToast.apiError(e);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('permissions.invites.invite_modal.title')}
    >
      <Flex flexDirection="column">
        <Text color="darkerSub">
          {t('permissions.invites.invite_modal.description')}
        </Text>
        <Text color="darkerSub">
          {t('permissions.invites.invite_modal.description-2')}
        </Text>
      </Flex>

      <Flex marginTop="s" flexDirection="column" gap="m" width={'100%'}>
        <Flex gap="s">
          <Controller
            control={control2}
            name="email"
            rules={{
              required: {
                value: true,
                message: t('common.required'),
              },
              pattern: {
                value: Regexes.EMAIL_REGEX,
                message: t('common.invalid.email'),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                placeholder="example@gmail.com"
                title={t('permissions.invites.invite_modal.member_email')}
                required
                type="email"
                onChange={onChange}
                value={value}
                errorMessage={error?.message}
                valid={!error}
              />
            )}
          />
          <Tooltip title={t('common.add')}>
            <AddButton
              onClick={handleSubmit2(onAddEmail, (e) => console.log(e))}
              icon={faPlus}
              iconWidth="lg"
            />
          </Tooltip>
        </Flex>

        <Flex flexDirection="column" gap="s">
          {fields.map((email, index) => (
            <EmailWrapper>
              <Flex alignItems="center" gap="s">
                <Text>{index + 1} - </Text>

                <TextWithTruncated length={40}>
                  {email.content}
                </TextWithTruncated>
              </Flex>

              <Tooltip title={t('common.remove')}>
                <DeleteButton icon={faX} onClick={() => remove(index)} />
              </Tooltip>
            </EmailWrapper>
          ))}
        </Flex>

        <SubmitButton
          loading={loading}
          onClick={handleSubmit(onInvite)}
          disabled={!fields.length}
        >
          {t('common.invite')}
        </SubmitButton>
      </Flex>
    </Modal>
  );
};
