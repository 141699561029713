import { CalculateForThresholdsDto } from '../../model/calculator/dto/CalculateForThresholdDto';
import { CalculateForThresholdsResponseDto } from '../../model/calculator/dto/CalculateForThresholdresponseDto';
import { GetPricesResponseDto } from '../../model/calculator/dto/GetPricesResponseDto';
import { AtiraApi } from '../AxiosClient';

export class CalculatorAxios {
  static calculateForThresholds(dto: CalculateForThresholdsDto) {
    return AtiraApi.post<CalculateForThresholdsResponseDto>(
      `/calculator/calc`,
      dto,
    ).then(({ data }) => data);
  }

  static getPrices() {
    return AtiraApi.get<GetPricesResponseDto>(`/calculator/prices`).then(
      ({ data }) => data,
    );
  }
}
